import storage from 'store'
import expirePlugin from 'store/plugins/expire'

storage.addPlugin(expirePlugin)
const user = {
  state: {
    vuex_productInventoryD_columns: {
			use: [],
			noUse: []
		}
  },
  mutations: {
    SET_vuex_productInventoryD_columns: (state, reload) => {
      state.vuex_productInventoryD_columns = reload
    }
  }
}

export default user
