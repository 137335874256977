/* eslint-disable */
export const printANSI = () => {
  // console.clear()
  console.log('[antd pro] created()')
  // ASCII - ANSI Shadow
  let text = `
  ███████╗██╗  ██╗ ██████╗ ███╗   ██╗ ██████╗     ██████╗ ███████╗
  ╚══███╔╝██║  ██║██╔═══██╗████╗  ██║██╔════╝     ██╔══██╗██╔════╝
    ███╔╝ ███████║██║   ██║██╔██╗ ██║██║  ███╗    ██║  ██║█████╗  
   ███╔╝  ██╔══██║██║   ██║██║╚██╗██║██║   ██║    ██║  ██║██╔══╝  
  ███████╗██║  ██║╚██████╔╝██║ ╚████║╚██████╔╝    ██████╔╝███████╗
  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝     ╚═════╝ ╚══════╝
                                                                  
  ██╗  ██╗ ██████╗ ███╗   ██╗ ██████╗     ██╗   ██╗███████╗       
  ██║  ██║██╔═══██╗████╗  ██║██╔════╝     ╚██╗ ██╔╝██╔════╝       
  ███████║██║   ██║██╔██╗ ██║██║  ███╗     ╚████╔╝ █████╗         
  ██╔══██║██║   ██║██║╚██╗██║██║   ██║      ╚██╔╝  ██╔══╝         
  ██║  ██║╚██████╔╝██║ ╚████║╚██████╔╝       ██║   ███████╗       
  ╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝        ╚═╝   ╚══════╝       
\t\t\t\t\tPublished ${APP_VERSION}-${GIT_HASH}
\t\t\t\t\tBuild date: ${BUILD_DATE}`
  // console.log(`%c${text}`, 'color: #fc4d50')
  // console.log('%c默认使用的路由初始化模式可能是 静态路由 / 动态路由, 请前往 src/store/index.js 确认 import permission from 哪一个文件.', 'color: #000; font-size: 14px;    font-family: Hiragino Sans GB,Microsoft YaHei,\\\\5FAE\\8F6F\\96C5\\9ED1,Droid Sans Fallback,Source Sans,Wenquanyi Micro Hei,WenQuanYi Micro Hei Mono,WenQuanYi Zen Hei,Apple LiGothic Medium,SimHei,ST Heiti,WenQuanYi Zen Hei Sharp,sans-serif;')
  console.log('')
}
