import store from '@/store'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import notification from 'ant-design-vue/es/notification'
import axios from 'axios'
import storage from 'store'
import Vue from 'vue'
import { VueAxios } from './axios'
import QS from 'qs' // 根据需求是否导入qs模块
import router from '@/router'

/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = (msg) => {
  Vue.prototype.$message.warning(msg)
}

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 2 * 60 * 1000, // 请求超时时间
  maxRedirects: 0, // 禁止重定向
  withCredentials: true, // 允许携带cookie
  validateStatus: function (status) {
    // return status >= 200 && status < 300 // 默认值
    return status === 200 // 默认值
  }
})
// 异常拦截处理器
const errorHandler = (error) => {
  console.log('接口异常拦截', error.toJSON())
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    const token = storage.get(ACCESS_TOKEN)
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}
/**
 * 请求成功后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const successError = (status, msg, url) => {
  // console.log(url, msg)
  // 状态码判断
  switch (status) {
    case 0:
      // tip(msg)
      if (url === '/pc/login/getPhoneByToken') {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
      notification.error({
        message: '操作异常',
        description: msg
      })
      break
    case 1:
      break
    // 401: 未登录状态，跳转登录页
    case 401:
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
      break
    case 404:
      console.log('router :>> ', router)
      router.push('/404')
      break
    case 201:
      store.dispatch('Logout').then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
      })
      break
    // 500网络异常
    case 500:
      tip(msg)
      break
    default:
      console.log(url, msg)
  }
}

// request interceptor
request.interceptors.request.use((config) => {
  const token = storage.get(ACCESS_TOKEN)
  const userType = storage.get('userType')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  if (token && userType !== '1') {
    config.headers[ACCESS_TOKEN] = token
  }
  // console.log('config', config)
  if (config && config.json) {
    config.headers['Content-Type'] = 'application/json'
  } else {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  if (config && config.upload) {
    config.headers['Content-Type'] = 'multipart/form-data;boundary =' + new Date().getTime()
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((res) => {
  // console.log('请求响应拦截', res)
  successError(res.data.code, res.data.msg, res.config.url)
  if (res.config && res.config.fullRes) {
    return Promise.resolve(res)
  } else {
    return Promise.resolve(res.data)
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const get = (url, params, config) => {
  return new Promise((resolve, reject) => {
    request
      .get(url, {
        params,
        config
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
const post = (url, params, config) => {
  return new Promise((resolve, reject) => {
    if (config && (!config.json && !config.upload)) {
      params = QS.stringify(params)
    }
    console.log(params)
    request
      .post(url, params, config)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export default request

export { installer as VueAxios, request as axios, get, post }
