export function timeFix () {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome () {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent () {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader (callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function () {}
  window.addEventListener(
    'scroll',
    (event) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE () {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate (id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}
export function scorePassword (pass) {
  let score = 0
  if (!pass) {
    return score
  }
  // award every unique letter until 5 repetitions
  const letters = {}
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1
    score += 5.0 / letters[pass[i]]
  }

  // bonus points for mixing it up
  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass)
  }

  let variationCount = 0
  for (var check in variations) {
    variationCount += variations[check] === true ? 1 : 0
  }
  score += (variationCount - 1) * 10

  return parseInt(score)
}
export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-23 17:46:18
 * @Desc: 转换文件大小
 * @param {Number} size
 */
export function sizeTostr (size) {
  if (!size) {
    return '0B'
  }
  if (typeof size === 'string') {
    return size
  }
  var data = ''
  if (size < 0.1 * 1024) {
    // 如果小于0.1KB转化成B
    data = size.toFixed(2) + 'B'
  } else if (size < 0.1 * 1024 * 1024) {
    // 如果小于0.1MB转化成KB
    data = (size / 1024).toFixed(2) + 'KB'
  } else if (size < 0.1 * 1024 * 1024 * 1024) {
    // 如果小于0.1GB转化成MB
    data = (size / (1024 * 1024)).toFixed(2) + 'MB'
  } else {
    // 其他转化成GB
    data = (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }
  var sizestr = data + ''
  var len = sizestr.indexOf('.')
  var dec = sizestr.substr(len + 1, 2)
  if (dec === '00') {
    // 当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
  }
  return sizestr
}
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-09-26 21:33:42
 * @Desc:  文件类型转对应图标
 * @param {string} type 文件后缀名
 */
export function fileType2Img (type) {
  // console.log('type', type)
  const Others = {
    icon: require('@/assets/fileIcon/Others.png'),
    name: '其他',
    value: 1
  }
  // const oldType = type
  if (!type || typeof type === 'number') {
    return Others
  }
  type = type.toLowerCase()
  const zipList = ['zip', 'rar', '7z', 'tar']
  if (zipList.includes(type)) type = 'zip'

  const DocList = ['doc', 'docx']
  if (DocList.includes(type)) type = 'doc'

  const xlsList = ['xls', 'xlsx']
  if (xlsList.includes(type)) type = 'xls'

  const imgList = ['bmp', 'jpg', 'jpeg', 'png', 'tif', 'gif', 'svg', 'webp', 'avif', 'apng']
  if (imgList.includes(type)) type = 'png'

  const audioList = ['mp3', 'wav', 'wma', 'flac', 'aac']
  if (audioList.includes(type)) type = 'mp3'

  const videoList = ['mp4', 'avi', 'wmv', 'mpg', 'mov', 'flv', 'swf']
  if (videoList.includes(type)) type = 'mp4'
  const fileTypeMap = {
    zip: {
      icon: require('@/assets/fileIcon/Zip.png'),
      name: 'ZIP',
      value: 1
    },
    doc: {
      icon: require('@/assets/fileIcon/Doc.png'),
      name: 'Doc',
      value: 1
    },
    xls: {
      icon: require('@/assets/fileIcon/Excel.png'),
      name: 'xls',
      value: 1
    },
    pdf: {
      icon: require('@/assets/fileIcon/PDF.png'),
      name: 'PDF',
      value: 1
    },
    png: {
      icon: require('@/assets/fileIcon/Photo.png'),
      name: 'png',
      value: 1
    },
    ppt: {
      icon: require('@/assets/fileIcon/PPT.png'),
      name: 'PPt',
      value: 1
    },
    mp3: {
      icon: require('@/assets/fileIcon/Music.png'),
      name: 'mp3',
      value: 1
    },
    mp4: {
      icon: require('@/assets/fileIcon/Video.png'),
      name: 'Ai',
      value: 1
    },
    xmind: {
      icon: require('@/assets/fileIcon/Xmind.png'),
      name: 'Xmind',
      value: 1
    },
    xml: {
      icon: require('@/assets/fileIcon/XML.png'),
      name: 'Ai',
      value: 1
    },
    pgs: {
      icon: require('@/assets/fileIcon/PGS.png'),
      name: 'PGS',
      value: 1
    },
    ae: {
      icon: require('@/assets/fileIcon/Ae.png'),
      name: 'Ae',
      value: 1
    },
    ai: {
      icon: require('@/assets/fileIcon/Ai.png'),
      name: 'Ai',
      value: 1
    },
    css: {
      icon: require('@/assets/fileIcon/CSS.png'),
      name: 'CSS',
      value: 1
    },
    folder: {
      icon: require('@/assets/fileIcon/Folder.png'),
      name: 'Folder',
      value: 1
    },
    font: {
      icon: require('@/assets/fileIcon/Font.png'),
      name: 'Font',
      value: 1
    },
    html: {
      icon: require('@/assets/fileIcon/Html.png'),
      name: 'Html',
      value: 1
    },
    js: {
      icon: require('@/assets/fileIcon/Js.png'),
      name: 'Js',
      value: 1
    },
    keynote: {
      icon: require('@/assets/fileIcon/Keynote.png'),
      name: 'Keynote',
      value: 1
    },
    ps: {
      icon: require('@/assets/fileIcon/Ps.png'),
      name: 'Ps',
      value: 1
    }
  }
  console.log('type :>> ', type)
  // fileTypeMap[type].name = oldType
  return fileTypeMap[type] || Others
}
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2024-01-04 16:49:29
 * @Desc: 数组对象去重
 */
export function unique (arr, key) {
  const map = new Map()
  for (const item of arr) {
    if (!map.has(item[key])) {
      map.set(item[key], item)
    }
  }
  return [...map.values()]
}
/**
 * javascript comment
 * @Author: flydreame
 * @Date: 2024-01-19 16:26:05
 * @Desc:  人民币大写金额
 * @param {number} [money=0] 数字金额
 */
export function smallToBig (money = 0) {
  // 汉字的数字
  const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  // 基本单位
  const cnIntRadice = ['', '拾', '佰', '仟']
  // 对应整数部分扩展单位
  const cnIntUnits = ['', '万', '亿', '兆']
  // 对应小数部分单位
  const cnDecUnits = ['角', '分']
  // 整数金额时后面跟的字符
  const cnInteger = '整'
  // 整型完以后的单位
  const cnIntLast = '元'
  // 最大处理的数字
  const maxNum = 9999999999999999.99
  // 金额整数部分
  let integerNum
  // 金额小数部分
  let decimalNum
  // 输出的中文金额字符串
  let chineseStr = ''
  // 分离金额后用的数组，预定义
  let parts
  if (money === '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    // 超出最大处理数字
    return ''
  }
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  // 转换为字符串
  money = money.toString()
  if (money.indexOf('.') === -1) {
    integerNum = money

    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // 获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    const IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      const n = integerNum.substr(i, 1)
      const p = IntLen - i - 1
      const q = p / 4
      const m = p % 4
      if (n === '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        // 归零
        zeroCount = 0
        // alert(cnNums[parseInt(n)])
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  // 小数部分
  if (decimalNum !== '') {
    const decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      const n = decimalNum.substr(i, 1)
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum === '') {
    chineseStr += cnInteger
  }
  return chineseStr
}
