import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import user from './modules/user'
import localDataLasting from './modules/localDataLasting'

// default router permission control
// 默认路由模式为静态路由 (router.config.js)
// import permission from './modules/static-router'

// dynamic router permission control (Experimental)
// 动态路由模式（api请求后端生成）
import permission from './modules/async-router'

import getters from './getters'

import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    user,
    permission,
    localDataLasting
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
  plugins: [
    createPersistedstate({
      key: 'AppData', // 存数据的key名   自定义的  要有语义化
      paths: ['localDataLasting'] // 要把那些模块加入缓存
    })
  ]

})
