const getters = {
  isMobile: state => state.app.isMobile,
  lang: state => state.app.lang,
  theme: state => state.app.theme,
  color: state => state.app.color,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  nickname: state => state.user.name,
  welcome: state => state.user.welcome,
  roles: state => state.user.roles,
  userInfo: state => state.user.info,
  vuex_companyList: state => state.user.vuex_companyList,
  vuex_reload: state => state.user.vuex_reload,
  baseUrl: state => state.user.baseUrl,
  showFooter: state => state.user.showFooter,
  msgNum: state => state.user.msgNum,
  addRouters: state => state.permission.addRouters,
  multiTab: state => state.app.multiTab
}

export default getters
