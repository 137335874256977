import storage from 'store'
import expirePlugin from 'store/plugins/expire'
import { login, getInfo, logout, getRoleParamsList } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import { ApiGetMessageRecordUnreadCount, companyCompanyList } from '@/api/zonghoutai'
import { initSse } from '@/utils/sse'

storage.addPlugin(expirePlugin)
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    vuex_reload: 0,
    baseUrl: process.env.VUE_APP_API_BASE_URL,
    showFooter: true,
    msgNum: 0,
    vuex_companyList: []
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_RELOAD: (state, reload) => {
      state.vuex_reload = state.vuex_reload + 1
    },
    SET_SHOWFOOTER: (state, reload) => {
      state.showFooter = reload
    },
    SET_MSGNUM: (state, reload) => {
      state.msgNum = reload
    },
    SET_COMPANYLIST: (state, reload) => {
      state.vuex_companyList = reload
    }
  },

  actions: {
    // 获取消息数
    getNum ({ commit, state }) {
      // console.log('getMsgNum', getMsgNum)
      ApiGetMessageRecordUnreadCount().then((res) => {
        if (res.code === 1) {
          commit('SET_MSGNUM', res.data)
        }
      })
    },
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            console.log('response :>> ', response)
            if (response.code === 1) {
              if (userInfo.rememberMe) {
                storage.set(ACCESS_TOKEN, response.data)
              } else {
                storage.set(ACCESS_TOKEN, response.data, new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
              }
              commit('SET_TOKEN', response.data)
              resolve()
            } else {
              reject(response)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        // 请求后端获取用户信息
        getInfo()
          .then((response) => {
            if (response.code === 1) {
              const { data: result } = response
              initSse(result.userId)
              console.log('getInfo下游数据', result)
              commit('SET_INFO', result)
              commit('SET_NAME', { name: result.userName, welcome: welcome() })
              commit('SET_AVATAR', result.avatar)
              // 下游
              resolve(result)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 获取菜单权限列表
    getRoleParamsListF ({ commit }) {
      getRoleParamsList().then((result) => {
        if (result.code === 1) {
          if (result.data.length > 0 && result.data[0].permissions.length > 0) {
            const roleList = []
            result.data.forEach((item) => {
              item.permissions.forEach((permission) => {
                if (roleList.findIndex((item) => item.permissionId === permission.permissionId) === -1) {
                  roleList.push(permission)
                }
              })
            })
            const role = { ...result.data[0] }
            console.log('roleList :>> ===========', roleList)
            role.permissions = roleList.map((permission) => {
              permission.actionEntitySet = JSON.parse(permission.actions)
              const per = {
                ...permission,
                actionList: (permission.actionEntitySet || {}).map((item) => item.action)
              }
              return per
            })
            role.permissionList = role.permissions.map((permission) => {
              return permission.permissionId
            })
            // 覆盖响应体的 role, 供下游使用
            // result.role = role

            // console.log('getInfo下游数据', result, role)
            // console.log('getInfo-role', role)
            commit('SET_ROLES', role)
          }
        }
      })
    },
    // 获取公司列表
    getCompanyList ({ commit }) {
      return new Promise((resolve, reject) => {
        // 请求后端获取用户信息 /api/user/info
        companyCompanyList({
          pageNo: 1,
          pageSize: 100
        })
          .then((res) => {
            if (res.code === 1) {
              // console.log('getInfo下游数据', result)
              commit('SET_COMPANYLIST', res.data.list)
              // 下游
              resolve(res)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            storage.remove(ACCESS_TOKEN)
            resolve()
          })
          .catch((err) => {
            console.log('logout fail:', err)
            // resolve()
          })
          .finally(() => { })
      })
    }
  }
}

export default user
