/**
 * Custom icon list
 * All icons are loaded here for easy management
 * @see https://vue.ant.design/components/icon/#Custom-Font-Icon
 *
 * 自定义图标加载表
 * 所有图标均从这里加载，方便管理
 */
import bxAnaalyse from '@/assets/icons/bx-analyse.svg?inline' // path to your '*.svg?inline' file.

// 菜单图标
import iconMenu01 from '@/assets/leftMenu/icon_01.svg?inline'
import iconMenu01active from '@/assets/leftMenu/icon_01_Active.svg?inline'
import iconMenu02 from '@/assets/leftMenu/icon_02.svg?inline'
import iconMenu02active from '@/assets/leftMenu/icon_02_Active.svg?inline'
import iconMenu03 from '@/assets/leftMenu/icon_03.svg?inline'
import iconMenu03active from '@/assets/leftMenu/icon_03_Active.svg?inline'
import iconMenu04 from '@/assets/leftMenu/icon_04.svg?inline'
import iconMenu04active from '@/assets/leftMenu/icon_04_Active.svg?inline'
import iconMenu05 from '@/assets/leftMenu/icon_05.svg?inline'
import iconMenu05active from '@/assets/leftMenu/icon_05_Active.svg?inline'
import iconMenu06 from '@/assets/leftMenu/icon_06.svg?inline'
import iconMenu06active from '@/assets/leftMenu/icon_06_Active.svg?inline'
import iconMenu07 from '@/assets/leftMenu/icon_07.svg?inline'
import iconMenu07active from '@/assets/leftMenu/icon_07_Active.svg?inline'
import iconMenu08 from '@/assets/leftMenu/icon_08.svg?inline'
import iconMenu08active from '@/assets/leftMenu/icon_08_Active.svg?inline'
import iconMenu09 from '@/assets/leftMenu/icon_09.svg?inline'
import iconMenu09active from '@/assets/leftMenu/icon_09_Active.svg?inline'
import iconMenu10 from '@/assets/leftMenu/icon_10.svg?inline'
import iconMenu10active from '@/assets/leftMenu/icon_10_Active.svg?inline'
import iconMenu11 from '@/assets/leftMenu/icon_11.svg?inline'
import iconMenu11active from '@/assets/leftMenu/icon_11_Active.svg?inline'
import iconMenu12 from '@/assets/leftMenu/icon_12.svg?inline'
import iconMenu12active from '@/assets/leftMenu/icon_12_Active.svg?inline'
import iconMenu13 from '@/assets/leftMenu/icon_13.svg?inline'
import iconMenu13active from '@/assets/leftMenu/icon_13_Active.svg?inline'
import iconMenu14 from '@/assets/leftMenu/icon_14.svg?inline'
import iconMenu14active from '@/assets/leftMenu/icon_14_Active.svg?inline'
import iconMenu15 from '@/assets/leftMenu/icon_15.svg?inline'
import iconMenu15active from '@/assets/leftMenu/icon_15_Active.svg?inline'
import iconMenu16 from '@/assets/leftMenu/icon_16.svg?inline'
import iconMenu16active from '@/assets/leftMenu/icon_16_Active.svg?inline'

// 首页OA审核图标
import iconHome01 from '@/assets/home/icon1.png'
import iconHome02 from '@/assets/home/icon2.png'
import iconHome03 from '@/assets/home/icon3.png'
import iconHome04 from '@/assets/home/icon4.png'
import iconHome05 from '@/assets/home/icon5.png'
import iconHome06 from '@/assets/home/icon6.png'
import iconHome07 from '@/assets/home/icon7.png'
import iconHome08 from '@/assets/home/icon8.png'

// 空状态图标
import iconEmpty from '@/assets/nodata.png'

// 菜单图标
const iconMenuMap = {
  iconMenu01: {
    icon: iconMenu01,
    iconActive: iconMenu01active
  },
  iconMenu02: {
    icon: iconMenu02,
    iconActive: iconMenu02active
  },
  iconMenu03: {
    icon: iconMenu03,
    iconActive: iconMenu03active
  },
  iconMenu04: {
    icon: iconMenu04,
    iconActive: iconMenu04active
  },
  iconMenu05: {
    icon: iconMenu05,
    iconActive: iconMenu05active
  },
  iconMenu06: {
    icon: iconMenu06,
    iconActive: iconMenu06active
  },
  iconMenu07: {
    icon: iconMenu07,
    iconActive: iconMenu07active
  },
  iconMenu08: {
    icon: iconMenu08,
    iconActive: iconMenu08active
  },
  iconMenu09: {
    icon: iconMenu09,
    iconActive: iconMenu09active
  },
  iconMenu10: {
    icon: iconMenu10,
    iconActive: iconMenu10active
  },
  iconMenu11: {
    icon: iconMenu11,
    iconActive: iconMenu11active
  },
  iconMenu12: {
    icon: iconMenu12,
    iconActive: iconMenu12active
  },
  iconMenu13: {
    icon: iconMenu13,
    iconActive: iconMenu13active
  },
  iconMenu14: {
    icon: iconMenu14,
    iconActive: iconMenu14active
  },
  iconMenu15: {
    icon: iconMenu15,
    iconActive: iconMenu15active
  },
  iconMenu16: {
    icon: iconMenu16,
    iconActive: iconMenu16active
  }
}

export {
  bxAnaalyse,
  iconHome01,
  iconHome02,
  iconHome03,
  iconHome04,
  iconHome05,
  iconHome06,
  iconHome07,
  iconHome08,
  iconMenuMap,
  iconEmpty
}
