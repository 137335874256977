<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import { domTitle, setDocumentTitle } from '@/utils/domUtil'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import Vue from 'vue'
import { iconEmpty } from '@/core/icons'

Vue.mixin({
  computed: {
    ...mapState({
      vuex_reload: (state) => state.user.vuex_reload,
      baseUrl: (state) => state.user.baseUrl,
      vuexInfo: (state) => state.user.info,
      msgNum: (state) => state.user.msgNum,
      vuex_companyList: (state) => state.user.vuex_companyList
    })
  },
  data () {
    return {
      iconEmpty
    }
  }
})
export default {
  data () {
    return {}
  },
  computed: {
    locale () {
      // 只是为了切换语言时，更新标题
      console.log('object :>> ', this.$route)
      const { title } = this.$route.meta
      title && setDocumentTitle(`${i18nRender(title)} - ${domTitle}`)

      return this.$i18n.getLocaleMessage(this.$store.getters.lang).antLocale
    }
  }
}
</script>
<style lang="less">
.btnPointer {
  cursor: pointer;
}
</style>
