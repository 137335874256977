<template>
  <global-footer class="footer custom-render">
    <!-- 合作链接 -->
    <template #links>
      <!-- <a href="https://www.github.com/vueComponent/pro-layout" target="_blank">Pro Layout</a> -->
    </template>
    <template #copyright>
      <div class="flex_AI_c flex_JC_c">
        <div style="margin-right: 24px">陕西中德弘业科技有限公司</div>
        <div><a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank">陕ICP备2023018140号-1</a></div>
      </div>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
<style>
.ant-pro-global-footer {
  margin: 0 0 10px 0;
}
</style>
