<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
  >
    <!-- Ads end -->

    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template #menuHeaderRender>
      <div class="header-logo">
        <img src="@/assets/logo5.png" />
        <img
          src="@/assets/logo7.png"
          style="width: 88px; margin-left: 15px" />
        <!-- <h1>{{ title }}</h1> -->
      </div>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
    <template #headerContentRender>
      <div>
        <a-tooltip title="刷新页面">
          <a-icon
            type="reload"
            style="font-size: 18px; cursor: pointer; color: #fff" @click="onReload" />
        </a-tooltip>
      </div>
    </template>

    <setting-drawer
      v-if="isDev"
      :settings="settings" @change="handleSettingChange">
      <div style="margin: 12px 0">This is SettingDrawer custom footer content.</div>
    </setting-drawer>
    <template #rightContentRender>
      <right-content
        :top-menu="settings.layout === 'topmenu'"
        :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <!-- custom footer / 自定义Footer -->
    <template #footerRender>
      <global-footer v-if="showFooter" />
    </template>
    <router-view />
  </pro-layout>
</template>

<script>
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'

export default {
  name: 'BasicLayout',
  components: {
    SettingDrawer,
    RightContent,
    GlobalFooter,
    Ads
  },
  data () {
    return {
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end
      isDev: process.env.NODE_ENV === 'development' || process.env.VUE_APP_PREVIEW === 'true',

      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      showFooter: (state) => state.user.showFooter
    })
  },
  created () {
    const routes = this.mainMenu.find((item) => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
    this.$store.dispatch('getNum')
  },
  mounted () {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
    // 窗口适配
    document.getElementsByClassName('ant-layout-sider')[0].style.height = '100%'
    // window.addEventListener('resize', (res) => {
    //   console.log('窗口变化 :>> ', window.devicePixelRatio, res.target.innerWidth, res.target.innerHeight)
    //   var screenWidth = res.target.innerWidth
    //   var Zoom = screenWidth / 1920
    //   document.body.style.zoom = Zoom.toFixed(2)
    // })
    // 监听整个页面的 copy 事件
    document.addEventListener('paste', function (e) {
      // clipboardData 对象是为通过编辑菜单、快捷菜单和快捷键执行的编辑操作所保留的，也就是你复制或者剪切内容
      console.log('粘贴e :>> ', e)
      const clipboardData = e.clipboardData || window.clipboardData
      // 如果 未复制或者未剪切，直接 return
      if (!clipboardData) return
      // Selection 对象 表示用户选择的文本范围或光标的当前位置。
      // 声明一个变量接收 -- 用户输入的剪切或者复制的文本转化为字符串
      let text = clipboardData.getData('text/plain')
      // 纯文本
      if (text) {
        // // 如果文本存在，首先取消默认行为
        if (e.target._prevClass === 'ant-input') {
          e.preventDefault()

          if (window.getSelection) {
            // 针对于ie11 10 9 safari
            const newNode = document.createElement('span')
            newNode.innerHTML = text
            window.getSelection().getRangeAt(0).insertNode(newNode)
            text = newNode.innerHTML
            // 删除newNode
            newNode.remove()
          } else {
            // 兼容ie10 9 8 7 6 5
            document.selection.createRange().pasteHTML(text)
          }

          const evtObj = document.createEvent('UIEvents')
          evtObj.initUIEvent('input', true, true, window, 1)
          e.target.value = text.trim()
          e.target.dispatchEvent(evtObj)
        }
      }
    })
  },
  methods: {
    i18nRender,
    onReload () {
      console.log('刷新按钮点击 :>> ', this.vuex_reload)
      this.$store.dispatch('getNum')
      this.$store.commit('SET_RELOAD', this.vuex_reload)
    },
    handleMediaQuery (val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse (val) {
      this.collapsed = val
    },
    handleSettingChange ({ type, value }) {
      console.log('type', type, value)
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './BasicLayout.less';
.header-logo {
  padding-left: 34px;
  color: #fff !important;
}
.header-logo h1 {
  color: #fff !important;
}
:deep(.ant-pro-sider-menu-logo) {
  padding-left: 0 !important;
}
// 顶部header左侧logo背景图
:deep(.ant-pro-sider-menu-logo) {
  background-color: transparent !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-image: url('~@/assets/header-bg.jpg') !important;
  background-position: 0 0 !important;
  background-repeat: no-repeat !important;
  background-size: 100vw 100% !important;
}
// 顶部header背景图
:deep(.ant-pro-global-header) {
  background-color: transparent !important;
  background-image: url('~@/assets/header-bg.jpg') !important;
  background-position: -112px 0 !important;
  background-repeat: no-repeat !important;
  background-size: 100vw 100% !important;
}
:deep(.ant-layout-footer) {
  padding: 0;
}
//折叠菜单图标颜色
:deep(.ant-pro-global-header-trigger) {
  color: #fff !important;
}
:deep(.ant-layout-header) {
  line-height: normal;
}
:deep(.ant-menu) .anticon {
  line-height: normal !important;
}
:deep(.ant-menu) .anticon svg {
  width: 28px;
  height: 28px;
}
:deep(.ant-menu-item) > a {
  display: flex;
  align-items: center;
}
:deep(.ant-menu-submenu-title) > span {
  display: flex !important;
  align-items: center !important;
}
</style>
