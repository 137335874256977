import Vue from 'vue'
import { appDownloadByPath } from '@/api/zonghoutai'

// base library
import {
  ConfigProvider,
  Layout,
  Input,
  InputNumber,
  Button,
  Switch,
  Radio,
  Checkbox,
  Select,
  Card,
  Form,
  Row,
  Col,
  Modal,
  Table,
  Tabs,
  Icon,
  Badge,
  Popover,
  Dropdown,
  List,
  Avatar,
  Breadcrumb,
  Steps,
  Spin,
  Menu,
  Drawer,
  Tooltip,
  Alert,
  Tag,
  Divider,
  DatePicker,
  TimePicker,
  Upload,
  Progress,
  Skeleton,
  Popconfirm,
  PageHeader,
  Result,
  Statistic,
  Descriptions,
  Space,
  message,
  notification,
  Tree,
  Pagination,
  Affix,
  Empty,
  Comment,
  Timeline,
  TreeSelect,
  Rate,
  Cascader,
  AutoComplete,
  Anchor
} from 'ant-design-vue'
import Viser from 'viser-vue'
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout'

// ext library
import VueCropper from 'vue-cropper'
import Dialog from '@/components/Dialog'
import MultiTab from '@/components/MultiTab'
import PageLoading from '@/components/PageLoading'
import PermissionHelper from '@/core/permission/permission'
import './directives/action'
import '@/utils/copy'

import 'viewerjs/dist/viewer.css' // 引入viewerjs的CSS
import Viewer from 'v-viewer'

import VueVideoPlayer from 'vue-video-player'

// 引入样式
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import defaultSettings from '@/config/defaultSettings'

import format from 'vue-text-format'

// 自定义图标库
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: defaultSettings.iconfontUrl
})
Vue.component('IconFont', IconFont)

// 使用组件
Vue.use(VueVideoPlayer)
Vue.use(Viewer)

Vue.use(ConfigProvider)
Vue.use(Layout)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Card)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(Modal)
Vue.use(Table)
Vue.use(Tabs)
Vue.use(Icon)
Vue.use(Badge)
Vue.use(Popover)
Vue.use(Dropdown)
Vue.use(List)
Vue.use(Avatar)
Vue.use(Breadcrumb)
Vue.use(Steps)
Vue.use(Spin)
Vue.use(Menu)
Vue.use(Drawer)
Vue.use(Tooltip)
Vue.use(Alert)
Vue.use(Tag)
Vue.use(Divider)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Upload)
Vue.use(Progress)
Vue.use(Skeleton)
Vue.use(Popconfirm)
Vue.use(PageHeader)
Vue.use(Result)
Vue.use(Statistic)
Vue.use(Descriptions)
Vue.use(Space)
Vue.use(Tree)
Vue.use(Pagination)
Vue.use(Affix)
Vue.use(Empty)
Vue.use(Comment)
Vue.use(Timeline)
Vue.use(TreeSelect)
Vue.use(Rate)
Vue.use(Cascader)
Vue.use(AutoComplete)
Vue.use(Anchor)
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.use(format)

Vue.prototype.$preView = (url) => {
  console.log('url :>> ', url)
  window.open(url, '_blank')
}
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-10-19 10:55:08
 * @Desc: 文件下载
 * @param {String} path 文件路径
 * @param {String} fileName 文件名
 */
Vue.prototype.$download = (path, fileName) => {
  console.log('文件路径', path)
  console.log('文件名称', fileName)
  // path = path + '?attname=' + fileName
  // window.open(path, '_blank')
  appDownloadByPath({ filePath: path, fileName }, { responseType: 'blob' }).then((res) => {
    console.log('下载结果', res)
    var url = window.URL.createObjectURL(res)
    var a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  })
}

Vue.use(Viser)
Vue.use(Dialog) // this.$dialog func
Vue.use(MultiTab)
Vue.use(PageLoading)
Vue.use(PermissionHelper)
Vue.use(VueCropper)
Vue.use(PageHeaderWrapper)

process.env.NODE_ENV !== 'production' && console.warn('[antd-pro] NOTICE: Antd use lazy-load.')
