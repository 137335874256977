// eslint-disable-next-line
import { UserLayout, BasicLayout, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse, iconMenuMap } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '工作台' },
    redirect: '/',
    children: [
      // 工作台
      {
        path: '/workbench',
        name: 'workbench',
        component: () => import('@/views/AllPages/workbench/workbench'),
        meta: { title: '工作台', icon: iconMenuMap['iconMenu01'].iconActive }
      },
      // 客户管理
      {
        path: '/customerManagement',
        redirect: '/customerManagement/customerListRouteView',
        component: RouteView,
        meta: { title: '客户管理', icon: iconMenuMap['iconMenu02'].iconActive },
        children: [
          {
            path: '/customerManagement/customerListRouteView',
            name: 'customerListRouteView',
            component: RouteView,
            meta: { title: '客户列表', keepAlive: true },
            redirect: '/customerManagement/customerListRouteView/customerList',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/customerManagement/customerListRouteView/customerList',
                name: 'customerList',
                component: () => import('@/views/AllPages/customerManagement/customerList'),
                meta: { title: '客户列表', keepAlive: true }
              },
              {
                path: '/customerManagement/customerListRouteView/invoiceRecord',
                name: 'invoiceRecord',
                component: () => import('@/views/AllPages/customerManagement/invoiceRecord'),
                meta: { title: '开票记录', keepAlive: true }
              },
              {
                path: '/customerManagement/customerListRouteView/invoiceRecordAdd',
                name: 'invoiceRecordAdd',
                component: () => import('@/views/AllPages/customerManagement/invoiceRecordAdd'),
                meta: { title: '新增开票记录', keepAlive: true }
              },
              {
                path: '/customerManagement/customerListRouteView/invoiceRecordDisc',
                name: 'invoiceRecordDisc',
                component: () => import('@/views/AllPages/customerManagement/invoiceRecordDisc'),
                meta: { title: '开票记录详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/customerManagement/inquiryRouteView',
            name: 'inquiryRouteView',
            component: RouteView,
            meta: { title: '询价管理', keepAlive: true },
            redirect: '/customerManagement/inquiryRouteView/inquiry',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/customerManagement/inquiryRouteView/inquiry',
                name: 'inquiry',
                component: () => import('@/views/AllPages/customerManagement/inquiry'),
                meta: { title: '询价管理', keepAlive: true }
              },
              {
                path: '/customerManagement/inquiryRouteView/inquiryDisc',
                name: 'inquiryDisc',
                component: () => import('@/views/AllPages/customerManagement/inquiryDisc'),
                meta: { title: '询价详情', keepAlive: true }
              },
              {
                path: '/customerManagement/inquiryRouteView/quotationAdd',
                name: 'quotationAdd',
                component: () => import('@/views/AllPages/customerManagement/quotationAdd'),
                meta: { title: '生成报价单', keepAlive: true }
              }
            ]
          },
          {
            path: '/customerManagement/quoteRouteView',
            name: 'quoteRouteView',
            component: RouteView,
            meta: { title: '报价管理', keepAlive: true },
            redirect: '/customerManagement/quoteRouteView/quote',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/customerManagement/quoteRouteView/quote',
                name: 'quote',
                component: () => import('@/views/AllPages/customerManagement/quote'),
                meta: { title: '报价管理', keepAlive: true }
              },
              {
                path: '/customerManagement/quoteRouteView/quoteDiscS',
                name: 'quoteDiscS',
                component: () => import('@/views/AllPages/customerManagement/quoteDiscS'),
                meta: { title: '提交详情', keepAlive: true }
              },
              {
                path: '/customerManagement/quoteRouteView/quoteDiscA',
                name: 'quoteDiscA',
                component: () => import('@/views/AllPages/customerManagement/quoteDiscA'),
                meta: { title: '审核详情', keepAlive: true }
              },
              {
                path: '/customerManagement/quoteRouteView/quoteDiscC',
                name: 'quoteDiscC',
                component: () => import('@/views/AllPages/customerManagement/quoteDiscC'),
                meta: { title: '抄送详情', keepAlive: true }
              },
              {
                path: '/customerManagement/quoteRouteView/contractAdd',
                name: 'contractAdd',
                component: () => import('@/views/AllPages/customerManagement/contractAdd'),
                meta: { title: '报价生成合同', keepAlive: true }
              }
            ]
          },
          {
            path: '/customerManagement/recordRouteView',
            name: 'recordRouteView',
            component: RouteView,
            meta: { title: '成交记录', keepAlive: true },
            redirect: '/customerManagement/recordRouteView/record',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/customerManagement/recordRouteView/record',
                name: 'record',
                component: () => import('@/views/AllPages/customerManagement/record'),
                meta: { title: '成交记录', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 合同管理
      {
        path: '/contractManagement',
        redirect: '/contractManagement/OAExamineMySubmitRouteView',
        component: RouteView,
        meta: { title: '合同管理', icon: iconMenuMap['iconMenu03'].iconActive },
        children: [
          {
            path: '/contractManagement/OAExamineMySubmitRouteView',
            name: 'OAExamineMySubmitRouteView',
            component: RouteView,
            meta: { title: '我提交的', keepAlive: true },
            redirect: '/contractManagement/OAExamineMySubmitRouteView/contractMySubmit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/contractManagement/OAExamineMySubmitRouteView/contractMySubmit',
                name: 'contractMySubmit',
                component: () => import('@/views/AllPages/contractManagement/contractMySubmit'),
                meta: { title: '我提交的', keepAlive: true }
              },
              {
                path: '/contractManagement/OAExamineMySubmitRouteView/contractDiscS',
                name: 'contractDiscS',
                component: () => import('@/views/AllPages/contractManagement/contractDiscS'),
                meta: { title: '我提交的合同', keepAlive: true }
              },
              {
                path: '/contractManagement/OAExamineMySubmitRouteView/contractEdit',
                name: 'contractEdit',
                component: () => import('@/views/AllPages/contractManagement/contractEdit'),
                meta: { title: '修改合同', keepAlive: true }
              },
              {
                path: '/contractManagement/OAExamineMySubmitRouteView/purchasingAdd',
                name: 'purchasingAdd',
                component: () => import('@/views/AllPages/contractManagement/purchasingAdd'),
                meta: { title: '合同生成采购单', keepAlive: true }
              }
            ]
          },
          {
            path: '/contractManagement/myAuditRouteView',
            name: 'myAuditRouteView',
            component: RouteView,
            meta: { title: '我审核的', keepAlive: true },
            redirect: '/contractManagement/myAuditRouteView/contractMyAudit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/contractManagement/myAuditRouteView/contractMyAudit',
                name: 'contractMyAudit',
                component: () => import('@/views/AllPages/contractManagement/contractMyAudit'),
                meta: { title: '我审核的', keepAlive: true }
              },
              {
                path: '/contractManagement/OAExamineMySubmitRouteView/contractDiscA',
                name: 'contractDiscA',
                component: () => import('@/views/AllPages/contractManagement/contractDiscA'),
                meta: { title: '我审批的合同', keepAlive: true }
              }
            ]
          },
          {
            path: '/contractManagement/contractMyCopyRouteView',
            name: 'contractMyCopyRouteView',
            component: RouteView,
            meta: { title: '抄送我的', keepAlive: true },
            redirect: '/contractManagement/contractMyCopyRouteView/contractMyCopy',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/contractManagement/contractMyCopyRouteView/contractMyCopy',
                name: 'contractMyCopy',
                component: () => import('@/views/AllPages/contractManagement/contractMyCopy'),
                meta: { title: '抄送我的', keepAlive: true }
              },
              {
                path: '/contractManagement/contractMyCopyRouteView/contractDiscC',
                name: 'contractDiscC',
                component: () => import('@/views/AllPages/contractManagement/contractDiscC'),
                meta: { title: '抄送我的合同', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 采购管理
      {
        path: '/purchasingManagement',
        redirect: '/purchasingManagement/purchasingManagementMySubmitRouteView',
        component: RouteView,
        meta: { title: '采购管理', icon: iconMenuMap['iconMenu04'].iconActive },
        children: [
          {
            path: '/purchasingManagement/toBePurchasedRouteView',
            name: 'toBePurchasedRouteView',
            component: RouteView,
            meta: { title: '待采购', keepAlive: true },
            redirect: '/purchasingManagement/toBePurchasedRouteView/toBePurchased',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/toBePurchasedRouteView/toBePurchased',
                name: 'toBePurchased',
                component: () => import('@/views/AllPages/purchasingManagement/toBePurchased'),
                meta: { title: '待采购', keepAlive: true }
              },
              {
                path: '/purchasingManagement/toBePurchasedRouteView/toBePurchasedDisc',
                name: 'toBePurchasedDisc',
                component: () => import('@/views/AllPages/purchasingManagement/toBePurchasedDisc'),
                meta: { title: '待采购详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/purchasingManagement/purchasingManagementMySubmitRouteView',
            name: 'purchasingManagementMySubmitRouteView',
            component: RouteView,
            meta: { title: '我提交的', keepAlive: true },
            redirect: '/purchasingManagement/purchasingManagementMySubmitRouteView/mySubmit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/purchasingManagementMySubmitRouteView/purchasingMySubmit',
                name: 'purchasingMySubmit',
                component: () => import('@/views/AllPages/purchasingManagement/purchasingMySubmit'),
                meta: { title: '我提交的', keepAlive: true }
              },
              {
                path: '/purchasingManagement/purchasingManagementMySubmitRouteView/purchasingMyAuditAdd',
                name: 'purchasingMyAuditAdd',
                component: () => import('@/views/AllPages/purchasingManagement/purchasingMyAuditAdd'),
                meta: { title: '新增采购', keepAlive: true }
              }
            ]
          },
          {
            path: '/purchasingManagement/myAuditRouteView',
            name: 'purchasingManagementMyAuditRouteView',
            component: RouteView,
            meta: { title: '我审核的', keepAlive: true },
            redirect: '/purchasingManagement/myAuditRouteView/myAudit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/myAuditRouteView/purchasingMyAudit',
                name: 'purchasingMyAudit',
                component: () => import('@/views/AllPages/purchasingManagement/purchasingMyAudit'),
                meta: { title: '我审核的', keepAlive: true }
              },
              {
                path: '/purchasingManagement/myAuditRouteView/purchasingMyAuditDisc',
                name: 'purchasingMyAuditDisc',
                component: () => import('@/views/AllPages/purchasingManagement/purchasingMyAuditDisc'),
                meta: { title: '我审核的采购详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/purchasingManagement/purchasingMyCopyRouteView',
            name: 'purchasingMyCopyRouteView',
            component: RouteView,
            meta: { title: '抄送我的', keepAlive: true },
            redirect: '/purchasingManagement/purchasingMyCopyRouteView/purchasingMyCopy',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/purchasingMyCopyRouteView/purchasingMyCopy',
                name: 'purchasingMyCopy',
                component: () => import('@/views/AllPages/purchasingManagement/purchasingMyCopy'),
                meta: { title: '抄送我的', keepAlive: true }
              },
              {
                path: '/purchasingManagement/purchasingMyCopyRouteView/purchasingMyCopyDisc',
                name: 'purchasingMyCopyDisc',
                component: () => import('@/views/AllPages/purchasingManagement/purchasingMyCopyDisc'),
                meta: { title: '抄送我的采购详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/purchasingManagement/purchaseEnquiryRouteView',
            name: 'purchaseEnquiryRouteView',
            component: RouteView,
            meta: { title: '采购询价', keepAlive: true },
            redirect: '/purchasingManagement/purchaseEnquiryRouteView/purchaseEnquiry',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/purchaseEnquiryRouteView/purchaseEnquiry',
                name: 'purchaseEnquiry',
                component: () => import('@/views/AllPages/purchasingManagement/purchaseEnquiry'),
                meta: { title: '采购询价', keepAlive: true }
              },
              {
                path: '/purchasingManagement/purchaseEnquiryRouteView/purchaseEnquiryDisc',
                name: 'purchaseEnquiryDisc',
                component: () => import('@/views/AllPages/purchasingManagement/purchaseEnquiryDisc'),
                meta: { title: '询价详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/purchasingManagement/purchasePaymentRouteView',
            name: 'purchasePaymentRouteView',
            component: RouteView,
            meta: { title: '采购付款', keepAlive: true },
            redirect: '/purchasingManagement/purchasePaymentRouteView/purchasePayment',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/purchasePaymentRouteView/purchasePayment',
                name: 'purchasePayment',
                component: () => import('@/views/AllPages/purchasingManagement/purchasePayment'),
                meta: { title: '采购付款', keepAlive: true }
              },
              {
                path: '/purchasingManagement/purchasePaymentRouteView/purchasePaymentDisc',
                name: 'purchasePaymentDisc',
                component: () => import('@/views/AllPages/purchasingManagement/purchasePaymentDisc'),
                meta: { title: '采购付款详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/purchasingManagement/supplierManagementRouteView',
            name: 'supplierManagementRouteView',
            component: RouteView,
            meta: { title: '供应商管理', keepAlive: true },
            redirect: '/purchasingManagement/supplierManagementRouteView/supplierManagement',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/purchasingManagement/supplierManagementRouteView/supplierManagement',
                name: 'supplierManagement',
                component: () => import('@/views/AllPages/purchasingManagement/supplierManagement'),
                meta: { title: '供应商管理', keepAlive: true }
              },
              {
                path: '/purchasingManagement/supplierManagementRouteView/supplierDisc',
                name: 'supplierDisc',
                component: () => import('@/views/AllPages/purchasingManagement/supplierDisc'),
                meta: { title: '供应商详情', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 销售单管理
      {
        path: '/salesTicketManagement',
        redirect: '/salesTicketManagement/OAExamineMySubmitRouteView',
        component: RouteView,
        meta: { title: '销售单管理', icon: iconMenuMap['iconMenu05'].iconActive },
        children: [
          {
            path: '/salesTicketManagement/OAExamineMySubmitRouteView',
            name: 'salesTicketManagementMySubmitRouteView',
            component: RouteView,
            meta: { title: '我提交的', keepAlive: true },
            redirect: '/salesTicketManagement/OAExamineMySubmitRouteView/mySubmit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/salesTicketManagement/OAExamineMySubmitRouteView/mySubmit',
                name: 'salesTicketMySubmit',
                component: () => import('@/views/AllPages/salesTicketManagement/mySubmit'),
                meta: { title: '我提交的', keepAlive: true }
              },
              {
                path: '/salesTicketManagement/OAExamineMySubmitRouteView/mySubmitAdd',
                name: 'mySubmitAdd',
                component: () => import('@/views/AllPages/salesTicketManagement/mySubmitAdd'),
                meta: { title: '新增销货单', keepAlive: true }
              }
            ]
          },
          {
            path: '/salesTicketManagement/myAuditRouteView',
            name: 'salesTicketManagementMyAuditRouteView',
            component: RouteView,
            meta: { title: '我审核的', keepAlive: true },
            redirect: '/salesTicketManagement/myAuditRouteView/myAudit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/salesTicketManagement/myAuditRouteView/myAudit',
                name: 'salesTicketMyAudit',
                component: () => import('@/views/AllPages/salesTicketManagement/myAudit'),
                meta: { title: '我审核的', keepAlive: true }
              },
              {
                path: '/salesTicketManagement/myAuditRouteView/salesTicketmyAuditDisc',
                name: 'salesTicketmyAuditDisc',
                component: () => import('@/views/AllPages/salesTicketManagement/myAuditDisc'),
                meta: { title: '我审核的销售单', keepAlive: true }
              }
            ]
          },
          {
            path: '/salesTicketManagement/myCopyRouteView',
            name: 'salesTicketManagementmyCopyRouteView',
            component: RouteView,
            meta: { title: '抄送我的', keepAlive: true },
            redirect: '/salesTicketManagement/myCopyRouteView/myCopy',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/salesTicketManagement/myCopyRouteView/salesTicketmyCopy',
                name: 'salesTicketmyCopy',
                component: () => import('@/views/AllPages/salesTicketManagement/myCopy'),
                meta: { title: '抄送我的', keepAlive: true }
              },
              {
                path: '/salesTicketManagement/myCopyRouteView/myCopyDisc',
                name: 'salesTicketmyCopyDisc',
                component: () => import('@/views/AllPages/salesTicketManagement/myCopyDisc'),
                meta: { title: '抄送我的销售单', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 库存管理
      {
        path: '/repertoryManagement',
        redirect: '/repertoryManagement/OAExamineMySubmitRouteView',
        component: RouteView,
        meta: { title: '库存管理', icon: iconMenuMap['iconMenu06'].iconActive },
        children: [
          {
            path: '/repertoryManagement/warehouseManagementRouteView',
            name: 'repertoryManagementwarehouseManagementRouteView',
            component: RouteView,
            meta: { title: '库房管理', keepAlive: true },
            redirect: '/repertoryManagement/warehouseManagementRouteView/warehouseManagement',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/warehouseManagementRouteView/warehouseManagement',
                name: 'warehouseManagement',
                component: () => import('@/views/AllPages/repertoryManagement/warehouseManagement'),
                meta: { title: '库房管理', keepAlive: true }
              },
              {
                path: '/repertoryManagement/warehouseManagementRouteView/warehouseDisc',
                name: 'warehouseDisc',
                component: () => import('@/views/AllPages/repertoryManagement/warehouseDisc'),
                meta: { title: '库房详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/productInventoryRouteView',
            name: 'repertoryManagementproductInventoryRouteView',
            component: RouteView,
            meta: { title: '产品库存', keepAlive: true },
            redirect: '/repertoryManagement/productInventoryRouteView/productInventory',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/productInventoryRouteView/productInventory',
                name: 'productInventory',
                component: () => import('@/views/AllPages/repertoryManagement/productInventory'),
                meta: { title: '产品库存', keepAlive: true }
              },
              {
                path: '/repertoryManagement/productInventoryRouteView/productInventoryTAdd',
                name: 'productInventoryTAdd',
                component: () => import('@/views/AllPages/repertoryManagement/productInventoryTAdd'),
                meta: { title: '成套产品', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/salesHistoryRouteView',
            name: 'repertoryManagementsalesHistoryRouteView',
            component: RouteView,
            meta: { title: '销售历史', keepAlive: true },
            redirect: '/repertoryManagement/salesHistoryRouteView/salesHistory',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/salesHistoryRouteView/salesHistory',
                name: 'salesHistory',
                component: () => import('@/views/AllPages/repertoryManagement/salesHistory'),
                meta: { title: '销售历史', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/filingInventoryRouteView',
            name: 'repertoryManagementfilingInventoryRouteView',
            component: RouteView,
            meta: { title: '报备库存', keepAlive: true },
            redirect: '/repertoryManagement/filingInventoryRouteView/filingInventory',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/filingInventoryRouteView/filingInventory',
                name: 'filingInventory',
                component: () => import('@/views/AllPages/repertoryManagement/filingInventory'),
                meta: { title: '报备库存', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/stockInManagementRouteView',
            name: 'repertoryManagementstockInManagementRouteView',
            component: RouteView,
            meta: { title: '入库管理', keepAlive: true },
            redirect: '/repertoryManagement/stockInManagementRouteView/stockInManagement',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/stockInManagementRouteView/stockInManagement',
                name: 'stockInManagement',
                component: () => import('@/views/AllPages/repertoryManagement/stockInManagement'),
                meta: { title: '入库管理', keepAlive: true }
              },
              {
                path: '/repertoryManagement/stockInManagementRouteView/stockInAdd',
                name: 'stockInAdd',
                component: () => import('@/views/AllPages/repertoryManagement/stockInAdd'),
                meta: { title: '新增入库', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/allotManagementRouteView',
            name: 'repertoryManagementallotManagementRouteView',
            component: RouteView,
            meta: { title: '调拨管理', keepAlive: true },
            redirect: '/repertoryManagement/allotManagementRouteView/allotManagement',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/allotManagementRouteView/allotManagement',
                name: 'allotManagement',
                component: () => import('@/views/AllPages/repertoryManagement/allotManagement'),
                meta: { title: '调拨管理', keepAlive: true }
              },
              {
                path: '/repertoryManagement/allotManagementRouteView/allotManagementAdd',
                name: 'allotManagementAdd',
                component: () => import('@/views/AllPages/repertoryManagement/allotManagementAdd'),
                meta: { title: '新增调货单', keepAlive: true }
              },
              {
                path: '/repertoryManagement/allotManagementRouteView/allotManagementDisc',
                name: 'allotManagementDisc',
                component: () => import('@/views/AllPages/repertoryManagement/allotManagementDisc'),
                meta: { title: '调货单详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/stockOutManagementRouteView',
            name: 'repertoryManagementstockOutManagementRouteView',
            component: RouteView,
            meta: { title: '出库管理', keepAlive: true },
            redirect: '/repertoryManagement/stockOutManagementRouteView/stockOutManagement',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/stockOutManagementRouteView/stockOutManagement',
                name: 'stockOutManagement',
                component: () => import('@/views/AllPages/repertoryManagement/stockOutManagement'),
                meta: { title: '出库管理', keepAlive: true }
              },
              {
                path: '/repertoryManagement/stockOutManagementRouteView/stockOutAdd',
                name: 'stockOutAdd',
                component: () => import('@/views/AllPages/repertoryManagement/stockOutAdd'),
                meta: { title: '新增出库', keepAlive: true }
              },
              {
                path: '/repertoryManagement/stockOutManagementRouteView/stockOutDisc',
                name: 'stockOutDisc',
                component: () => import('@/views/AllPages/repertoryManagement/stockOutDisc'),
                meta: { title: '新增出库', keepAlive: true }
              }
            ]
          },
          {
            path: '/repertoryManagement/materialsManagementRouteView',
            name: 'repertoryManagementmaterialsManagementRouteView',
            component: RouteView,
            meta: { title: '物资库', keepAlive: true },
            redirect: '/repertoryManagement/materialsManagementRouteView/materialsManagement',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/repertoryManagement/materialsManagementRouteView/materialsManagement',
                name: 'materialsManagement',
                component: () => import('@/views/AllPages/repertoryManagement/materialsManagement'),
                meta: { title: '物资库', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 财务管理
      {
        path: '/financeManagement',
        redirect: '/financeManagement/receiptRouteView',
        component: RouteView,
        meta: { title: '财务管理', icon: iconMenuMap['iconMenu07'].iconActive },
        children: [
          {
            path: '/financeManagement/receiptRouteView',
            name: 'financeManagementreceiptRouteView',
            component: RouteView,
            meta: { title: '财务收款', keepAlive: true },
            redirect: '/financeManagement/receiptRouteView/receipt',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/financeManagement/receiptRouteView/receipt',
                name: 'receipt',
                component: () => import('@/views/AllPages/financeManagement/receipt'),
                meta: { title: '财务收款', keepAlive: true }
              },
              {
                path: '/financeManagement/receiptRouteView/receiptDisc',
                name: 'receiptDisc',
                component: () => import('@/views/AllPages/financeManagement/receiptDisc'),
                meta: { title: '财务收款详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/financeManagement/paymentRouteView',
            name: 'financeManagementpaymentRouteView',
            component: RouteView,
            meta: { title: '财务付款', keepAlive: true },
            redirect: '/financeManagement/paymentRouteView/payment',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/financeManagement/paymentRouteView/payment',
                name: 'payment',
                component: () => import('@/views/AllPages/financeManagement/payment'),
                meta: { title: '财务付款', keepAlive: true }
              },
              {
                path: '/financeManagement/paymentRouteView/paymentDisc',
                name: 'paymentDisc',
                component: () => import('@/views/AllPages/financeManagement/paymentDisc'),
                meta: { title: '采购付款详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/financeManagement/invoiceRouteView',
            name: 'financeManagementinvoiceRouteView',
            component: RouteView,
            meta: { title: '发票管理', keepAlive: true },
            redirect: '/financeManagement/invoiceRouteView/invoice',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/financeManagement/invoiceRouteView/invoice',
                name: 'invoice',
                component: () => import('@/views/AllPages/financeManagement/invoice'),
                meta: { title: '发票管理', keepAlive: true }
              },
              {
                path: '/financeManagement/invoiceRouteView/invoiceDisc',
                name: 'invoiceDisc',
                component: () => import('@/views/AllPages/financeManagement/invoiceDisc'),
                meta: { title: '销项发票详情', keepAlive: true }
              },
              {
                path: '/financeManagement/invoiceRouteView/invoiceEnterDisc',
                name: 'invoiceEnterDisc',
                component: () => import('@/views/AllPages/financeManagement/invoiceEnterDisc'),
                meta: { title: '进项发票详情', keepAlive: true }
              }
            ]
          }
        ]
      },
      // OA审批
      {
        path: '/OAExamine',
        redirect: '/OAExamine/OAExamineMySubmitRouteView',
        component: RouteView,
        meta: { title: 'OA审批', icon: iconMenuMap['iconMenu08'].iconActive },
        children: [
          {
            path: '/OAExamine/OAExamineMySubmitRouteView',
            name: 'OAExamineMySubmitRouteView',
            component: RouteView,
            meta: { title: '我提交的', keepAlive: true },
            redirect: '/OAExamine/OAExamineMySubmitRouteView/mySubmit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/mySubmit',
                name: 'OAMySubmit',
                component: () => import('@/views/AllPages/OAExamine/mySubmit'),
                meta: { title: '我提交的', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myBuka',
                name: 'myBuka',
                component: () => import('@/views/AllPages/OAExamine/myBuka'),
                meta: { title: '补卡申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myBukaDetail',
                name: 'myBuka',
                component: () => import('@/views/AllPages/OAExamine/myBukaDetail'),
                meta: { title: '补卡申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myBukaModification',
                name: 'myBukaModification',
                component: () => import('@/views/AllPages/OAExamine/myBukaModification'),
                meta: { title: '补卡修改', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myStampModification',
                name: 'myStampModification',
                component: () => import('@/views/AllPages/OAExamine/myStampModification'),
                meta: { title: '用印详情修改', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/travelExpenses',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/travelExpenses'),
                meta: { title: '报销申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/travelExpensesDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/travelExpensesDetail'),
                meta: { title: '报销申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myStamp',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/myStamp'),
                meta: { title: '用印申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myStampDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/myStampDetail'),
                meta: { title: '用印申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myLeave',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/myLeave'),
                meta: { title: '请假申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myLeaveDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/myLeaveDetail'),
                meta: { title: '请假申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/otherApply',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/otherApply'),
                meta: { title: '其他审批', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/otherApplyDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/otherApplyDetail'),
                meta: { title: '其他审批详情', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/projectSettlementOffset',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/projectSettlementOffset'),
                meta: { title: '请款冲抵审批', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/projectSettlementOffsetDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/projectSettlementOffsetDetail'),
                meta: { title: '请款冲抵审批', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/projectSettlement',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/projectSettlement'),
                meta: { title: '项目结算', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/projectSettlementDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/projectSettlementDetail'),
                meta: { title: '项目结算详情', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myApplymoney',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/myApplymoney'),
                meta: { title: '请款审批', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/myApplymoneyDetail',
                name: 'travelExpenses',
                component: () => import('@/views/AllPages/OAExamine/myApplymoneyDetail'),
                meta: { title: '请款审批详情', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/evectionAdd',
                name: 'evectionAdd',
                component: () => import('@/views/AllPages/OAExamine/evectionAdd'),
                meta: { title: '出差申请', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/evectionDisc',
                name: 'evectionDisc',
                component: () => import('@/views/AllPages/OAExamine/evectionDisc'),
                meta: { title: '出差申请详情', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/expensesClaimsAdd',
                name: 'expensesClaimsAdd',
                component: () => import('@/views/AllPages/OAExamine/expensesClaimsAdd'),
                meta: { title: '费用报销', keepAlive: true }
              },
              {
                path: '/OAExamine/OAExamineMySubmitRouteView/expensesClaimsSubmitDetail',
                name: 'expensesClaimsSubmitDetail',
                component: () => import('@/views/AllPages/OAExamine/expensesClaimsSubmitDetail'),
                meta: { title: '费用报销详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/OAExamine/myAuditRouteView',
            name: 'OAExamineMyAuditRouteView',
            component: RouteView,
            meta: { title: '我审批的', keepAlive: true },
            redirect: '/OAExamine/myAuditRouteView/myAudit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/OAExamine/myAuditRouteView/myAudit',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/myAudit'),
                meta: { title: '我审批的', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditmyBukaDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditmyBukaDetail'),
                meta: { title: '补卡详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/audittravelExpensesDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/audittravelExpensesDetail'),
                meta: { title: '报销详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditmyStampDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditmyStampDetail'),
                meta: { title: '用印详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditmyLeaveDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditmyLeaveDetail'),
                meta: { title: '请假详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditmyApplymoneyDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditmyApplymoneyDetail'),
                meta: { title: '请款详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditprojectSettlementOffsetDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditprojectSettlementOffsetDetail'),
                meta: { title: '冲抵详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditprojectSettlementDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditprojectSettlementDetail'),
                meta: { title: '项目结算详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/auditotherApplyDetail',
                name: 'OAMyAudit',
                component: () => import('@/views/AllPages/OAExamine/auditotherApplyDetail'),
                meta: { title: '其他详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/evectionAuditDetail',
                name: 'evectionAuditDetail',
                component: () => import('@/views/AllPages/OAExamine/evectionAuditDetail'),
                meta: { title: '出差审批详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myAuditRouteView/expensesClaimsAuditDetail',
                name: 'expensesClaimsAuditDetail',
                component: () => import('@/views/AllPages/OAExamine/expensesClaimsAuditDetail'),
                meta: { title: '费用报销审批详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/OAExamine/myBCCRouteView',
            name: 'OAExaminemyBCCRouteView',
            component: RouteView,
            meta: { title: '抄送我的', keepAlive: true },
            redirect: '/OAExamine/myBCCRouteView/myBCC',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/OAExamine/myBCCRouteView/myBCC',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/myBCC'),
                meta: { title: '抄送我的', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copymyBukaDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copymyBukaDetail'),
                meta: { title: '补卡详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copytravelExpensesDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copytravelExpensesDetail'),
                meta: { title: '报销详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copymyStampDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copymyStampDetail'),
                meta: { title: '用印详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copymyLeaveDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copymyLeaveDetail'),
                meta: { title: '请假详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copymyApplymoneyDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copymyApplymoneyDetail'),
                meta: { title: '请款详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copyprojectSettlementOffsetDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copyprojectSettlementOffsetDetail'),
                meta: { title: '冲抵详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copyprojectSettlementDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copyprojectSettlementDetail'),
                meta: { title: '项目结算详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/copyotherApplyDetail',
                name: 'OAmyBCC',
                component: () => import('@/views/AllPages/OAExamine/copyotherApplyDetail'),
                meta: { title: '其他审批详情', keepAlive: true }
              },
              {
                path: '/OAExamine/myBCCRouteView/expensesClaimsCopyDetail',
                name: 'expensesClaimsCopyDetail',
                component: () => import('@/views/AllPages/OAExamine/expensesClaimsCopyDetail'),
                meta: { title: '出差抄送详情', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 工作计划
      {
        path: '/workPlan',
        redirect: '/workPlan/myWorkPlanRouteView',
        component: RouteView,
        meta: { title: '工作计划', icon: iconMenuMap['iconMenu09'].iconActive },
        children: [
          {
            path: '/workPlan/workPlanRouteView',
            name: 'workPlanRouteView',
            component: RouteView,
            meta: { title: '我的工作计划', keepAlive: true },
            redirect: '/workPlan/workPlanRouteView/workPlan',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/workPlan/workPlanRouteView/workPlan',
                name: 'workPlan',
                component: () => import('@/views/AllPages/workPlan/workPlan'),
                meta: { title: '我的工作计划', keepAlive: true }
              },
              {
                path: '/workPlan/workPlanRouteView/workPlanAdd',
                name: 'workPlanAdd',
                component: () => import('@/views/AllPages/workPlan/workPlanAdd'),
                meta: { title: '新增工作计划', keepAlive: true }
              },
              {
                path: '/workPlan/workPlanRouteView/workPlanDisc',
                name: 'workPlanDisc',
                component: () => import('@/views/AllPages/workPlan/workPlanDisc'),
                meta: { title: '我的工作计划详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/workPlan/copyWorkPlanRouteView',
            name: 'copyWorkPlanRouteView',
            component: RouteView,
            meta: { title: '抄送我的工作计划', keepAlive: true },
            redirect: '/workPlan/copyWorkPlanRouteView/copyWorkPlan',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/workPlan/copyWorkPlanRouteView/copyWorkPlan',
                name: 'copyWorkPlan',
                component: () => import('@/views/AllPages/workPlan/copyWorkPlan'),
                meta: { title: '抄送我的工作计划', keepAlive: true }
              },
              {
                path: '/workPlan/copyWorkPlanRouteView/copyWorkPlanDisc',
                name: 'copyWorkPlanDisc',
                component: () => import('@/views/AllPages/workPlan/copyWorkPlanDisc'),
                meta: { title: '抄送我的工作计划详情', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 任务管理
      {
        path: '/taskManagement',
        redirect: '/taskManagement/myTaskRouteView',
        component: RouteView,
        meta: { title: '任务管理', icon: iconMenuMap['iconMenu09'].iconActive },
        children: [
          {
            path: '/taskManagement/myCreatTaskRouteView',
            name: 'myCreatTaskRouteView',
            component: RouteView,
            meta: { title: '我创建的任务', keepAlive: true },
            redirect: '/taskManagement/myCreatTaskRouteView/myCreatTask',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/taskManagement/myCreatTaskRouteView/myCreatTask',
                name: 'myCreatTask',
                component: () => import('@/views/AllPages/taskManagement/myCreatTask'),
                meta: { title: '我创建的任务', keepAlive: true }
              },
              {
                path: '/taskManagement/myCreatTaskRouteView/myCreatTaskAdd',
                name: 'myCreatTaskAdd',
                component: () => import('@/views/AllPages/taskManagement/myCreatTaskAdd'),
                meta: { title: '创建任务', keepAlive: true }
              },
              {
                path: '/taskManagement/myCreatTaskRouteView/myAddTaskDetail',
                name: 'myAddTaskDetail',
                component: () => import('@/views/AllPages/taskManagement/myAddTaskDetail'),
                meta: { title: '任务详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/taskManagement/myTaskRouteView',
            name: 'taskManagementmyTaskRouteView',
            component: RouteView,
            meta: { title: '我的任务', keepAlive: true },
            redirect: '/taskManagement/myTaskRouteView/myTask',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/taskManagement/myTaskRouteView/myTask',
                name: 'myTask',
                component: () => import('@/views/AllPages/taskManagement/myTask'),
                meta: { title: '我的任务', keepAlive: true }
              },
              {
                path: '/taskManagement/myTaskRouteView/myTaskDetail',
                name: 'myTaskDetail',
                component: () => import('@/views/AllPages/taskManagement/myTaskDetail'),
                meta: { title: '任务详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/taskManagement/myTaskAuditRouteView',
            name: 'taskManagementmyTaskAuditRouteView',
            component: RouteView,
            meta: { title: '我审核的', keepAlive: true },
            redirect: '/taskManagement/myTaskAuditRouteView/myTaskAudit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/taskManagement/myTaskAuditRouteView/myTaskAudit',
                name: 'myTaskAudit',
                component: () => import('@/views/AllPages/taskManagement/myTaskAudit'),
                meta: { title: '我审核的', keepAlive: true }
              },
              {
                path: '/taskManagement/myTaskAuditRouteView/TaskDetail',
                name: 'TaskDetail',
                component: () => import('@/views/AllPages/taskManagement/TaskDetail'),
                meta: { title: '任务详情', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 会议管理
      {
        path: '/conferenceManagement',
        redirect: '/conferenceManagement/allConferenceRouteView',
        component: RouteView,
        meta: { title: '会议管理', icon: iconMenuMap['iconMenu10'].iconActive },
        children: [
          {
            path: '/conferenceManagement/allConferenceRouteView',
            name: 'conferenceManagementallConferenceRouteView',
            component: RouteView,
            meta: { title: '全部会议', keepAlive: true },
            redirect: '/conferenceManagement/allConferenceRouteView/allConference',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/conferenceManagement/allConferenceRouteView/allConference',
                name: 'allConference',
                component: () => import('@/views/AllPages/conferenceManagement/allConference'),
                meta: { title: '全部会议', keepAlive: true }
              }
            ]
          },
          {
            path: '/conferenceManagement/conferenceSubmitRouteView',
            name: 'conferenceManagementconferenceSubmitRouteView',
            component: RouteView,
            meta: { title: '我提交的', keepAlive: true },
            redirect: '/conferenceManagement/conferenceSubmitRouteView/conferenceSubmit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/conferenceManagement/conferenceSubmitRouteView/conferenceSubmit',
                name: 'conferenceSubmit',
                component: () => import('@/views/AllPages/conferenceManagement/conferenceSubmit'),
                meta: { title: '我提交的', keepAlive: true }
              },
              {
                path: '/conferenceManagement/conferenceSubmitRouteView/createConference',
                name: 'createConference',
                component: () => import('@/views/AllPages/conferenceManagement/createConference'),
                meta: { title: '创建会议', keepAlive: true }
              }
            ]
          },
          {
            path: '/conferenceManagement/conferenceAuditRouteView',
            name: 'conferenceManagementconferenceAuditRouteView',
            component: RouteView,
            meta: { title: '我审核的', keepAlive: true },
            redirect: '/conferenceManagement/conferenceAuditRouteView/conferenceAudit',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/conferenceManagement/conferenceAuditRouteView/conferenceAudit',
                name: 'conferenceAudit',
                component: () => import('@/views/AllPages/conferenceManagement/conferenceAudit'),
                meta: { title: '我审核的', keepAlive: true }
              }
            ]
          },
          {
            path: '/conferenceManagement/conferenceSubmitRouteView/conferenceDisc',
            name: 'conferenceDisc',
            component: () => import('@/views/AllPages/conferenceManagement/conferenceDisc'),
            hideMenu: true,
            meta: { title: '会议详情', keepAlive: true }
          }
        ]
      },
      // 人事管理
      {
        path: '/personnelManagement',
        redirect: '/personnelManagement/personnelManagementAttendanceRouteView',
        component: RouteView,
        meta: { title: '人事管理', icon: iconMenuMap['iconMenu11'].iconActive },
        children: [
          {
            path: '/personnelManagement/personnelManagementAttendanceRouteView',
            name: 'personnelManagementAttendanceRouteView',
            component: RouteView,
            meta: { title: '考勤管理', keepAlive: true },
            redirect: '/personnelManagement/personnelManagementAttendanceRouteView/attendance',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/personnelManagement/personnelManagementAttendanceRouteView/attendance',
                name: 'attendance',
                component: () => import('@/views/AllPages/personnelManagement/attendance'),
                meta: { title: '考勤统计', keepAlive: true }
              }
            ]
          },
          {
            path: '/personnelManagement/staffRouteView',
            name: 'personnelManagementstaffRouteView',
            component: RouteView,
            meta: { title: '人员管理', keepAlive: true },
            redirect: '/personnelManagement/staffRouteView/staff',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/personnelManagement/staffRouteView/staff',
                name: 'staff',
                component: () => import('@/views/AllPages/personnelManagement/staff'),
                meta: { title: '人员管理', keepAlive: true }
              },
              {
                path: '/personnelManagement/staffRouteView/staffForm',
                name: 'staffForm',
                component: () => import('@/views/AllPages/personnelManagement/staffForm'),
                meta: { title: '编辑人员', keepAlive: true }
              }
            ]
          },
          {
            path: '/personnelManagement/departmentRouteView',
            name: 'personnelManagementdepartmentRouteView',
            component: RouteView,
            meta: { title: '部门管理', keepAlive: true },
            redirect: '/personnelManagement/departmentRouteView/department',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/personnelManagement/departmentRouteView/department',
                name: 'department',
                component: () => import('@/views/AllPages/personnelManagement/department'),
                meta: { title: '部门管理', keepAlive: true }
              }
            ]
          },
          {
            path: '/personnelManagement/officeRouteView',
            name: 'personnelManagementofficeRouteView',
            component: RouteView,
            meta: { title: '公司管理', keepAlive: true },
            redirect: '/personnelManagement/officeRouteView/office',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/personnelManagement/officeRouteView/office',
                name: 'office',
                component: () => import('@/views/AllPages/personnelManagement/office'),
                meta: { title: '公司管理', keepAlive: true }
              }
            ]
          },
          {
            path: '/personnelManagement/postRouteView',
            name: 'personnelManagementpostRouteView',
            component: RouteView,
            meta: { title: '岗位管理', keepAlive: true },
            redirect: '/personnelManagement/postRouteView/post',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/personnelManagement/postRouteView/post',
                name: 'post',
                component: () => import('@/views/AllPages/personnelManagement/post'),
                meta: { title: '岗位管理', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 企业文化
      {
        path: '/corporateCulture',
        redirect: '/corporateCulture/circleRouteView',
        component: RouteView,
        meta: { title: '企业文化', icon: iconMenuMap['iconMenu12'].iconActive },
        children: [
          {
            path: '/corporateCulture/circleRouteView',
            name: 'corporateCulturecircleRouteView',
            component: RouteView,
            meta: { title: '圈子', keepAlive: true },
            redirect: '/corporateCulture/circleRouteView/circle',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/corporateCulture/circleRouteView/circle',
                name: 'circle',
                component: () => import('@/views/AllPages/corporateCulture/circle'),
                meta: { title: '圈子', keepAlive: true }
              },
              {
                path: '/corporateCulture/circleRouteView/circleDisc',
                name: 'circleDisc',
                component: () => import('@/views/AllPages/corporateCulture/circleDisc'),
                meta: { title: '圈子详情', keepAlive: true }
              },
              {
                path: '/corporateCulture/circleRouteView/dynamicDisc',
                name: 'dynamicDisc',
                component: () => import('@/views/AllPages/corporateCulture/dynamicDisc'),
                meta: { title: '动态详情', keepAlive: true }
              }
            ]
          },
          {
            path: '/corporateCulture/leagueRouteView',
            name: 'corporateCultureleagueRouteView',
            component: RouteView,
            meta: { title: '公司团建', keepAlive: true },
            redirect: '/corporateCulture/leagueRouteView/league',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/corporateCulture/leagueRouteView/league',
                name: 'league',
                component: () => import('@/views/AllPages/corporateCulture/league'),
                meta: { title: '公司团建', keepAlive: true }
              },
              {
                path: '/corporateCulture/leagueRouteView/leagueDisc',
                name: 'leagueDisc',
                component: () => import('@/views/AllPages/corporateCulture/leagueDisc'),
                meta: { title: '团建详情', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 资料共享库
      {
        path: '/dataSharedLibrary',
        redirect: '/dataSharedLibrary/resourceFileRouteView',
        component: RouteView,
        meta: { title: '资料共享库', icon: iconMenuMap['iconMenu13'].iconActive },
        children: [
          {
            path: '/dataSharedLibrary/resourceFileRouteView',
            name: 'dataSharedLibraryresourceFileRouteView',
            component: RouteView,
            meta: { title: '资源文件', keepAlive: true },
            redirect: '/dataSharedLibrary/resourceFileRouteView/resourceFile',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/dataSharedLibrary/resourceFileRouteView/resourceFile',
                name: 'resourceFile',
                component: () => import('@/views/AllPages/dataSharedLibrary/resourceFile'),
                meta: { title: '资源文件', keepAlive: true }
              }
            ]
          },
          {
            path: '/dataSharedLibrary/bidingDocumentRouteView',
            name: 'dataSharedLibrarybidingDocumentRouteView',
            component: RouteView,
            meta: { title: '标书管理', keepAlive: true },
            redirect: '/dataSharedLibrary/bidingDocumentRouteView/bidingDocument',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/dataSharedLibrary/bidingDocumentRouteView/bidingDocument',
                name: 'bidingDocument',
                component: () => import('@/views/AllPages/dataSharedLibrary/bidingDocument'),
                meta: { title: '标书管理', keepAlive: true }
              }
            ]
          },
          {
            path: '/dataSharedLibrary/externalLinksRouteView',
            name: 'dataSharedLibraryexternalLinksRouteView',
            component: RouteView,
            meta: { title: '外部链接', keepAlive: true },
            redirect: '/dataSharedLibrary/externalLinksRouteView/externalLinks',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/dataSharedLibrary/externalLinksRouteView/externalLinks',
                name: 'externalLinks',
                component: () => import('@/views/AllPages/dataSharedLibrary/externalLinks'),
                meta: { title: '外部链接', keepAlive: true }
              }
            ]
          }
        ]
      },
      // 设置管理
      {
        path: '/settingManagement',
        redirect: '/settingManagement/systemRoleRouteView/systemRole',
        component: RouteView,
        meta: { title: '设置管理', icon: iconMenuMap['iconMenu13'].iconActive },
        children: [
          {
            path: '/settingManagement/departmentRouteView/department',
            name: 'department',
            component: () => import('@/views/AllPages/settingManagement/department'),
            meta: { title: '部门管理', keepAlive: true }
          },
          {
            path: '/settingManagement/systemRoleRouteView/systemRole',
            name: 'systemRole',
            component: () => import('@/views/AllPages/settingManagement/systemRole'),
            meta: { title: '角色管理', keepAlive: true }
          },
          {
            path: '/settingManagement/systemDepartmentMemberRouteView/systemDepartmentMember',
            name: 'systemDepartmentMember',
            component: () => import('@/views/AllPages/settingManagement/systemDepartmentMember'),
            meta: { title: '成员管理', keepAlive: true }
          },
          {
            path: '/settingManagement/parameterSettingRouteView',
            name: 'settingManagementparameterSettingRouteView',
            component: RouteView,
            meta: { title: '字典管理', keepAlive: true },
            redirect: '/settingManagement/parameterSettingRouteView/parameterSetting',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/settingManagement/parameterSettingRouteView/parameterSetting',
                name: 'parameterSetting',
                component: () => import('@/views/AllPages/settingManagement/parameterSetting'),
                meta: { title: '字典管理', keepAlive: true }
              },
              {
                path: '/settingManagement/parameterDataRouteView/parameterData',
                name: 'parameterData',
                component: () => import('@/views/AllPages/settingManagement/parameterData'),
                meta: { title: '字典数据', keepAlive: true }
              }
            ]
          },
          {
            path: '/settingManagement/systemLogRouteView/systemLog',
            name: 'systemLog',
            component: () => import('@/views/AllPages/settingManagement/systemLog'),
            meta: { title: '日志管理', keepAlive: true }
          }
        ]
      },
      // 消息管理
      {
        path: '/message',
        redirect: '/message/messageRouteView/message',
        component: RouteView,
        meta: { title: '消息管理', icon: iconMenuMap['iconMenu13'].iconActive },
        children: [
          {
            path: '/message/messageRouteView/message',
            name: 'message',
            component: () => import('@/views/AllPages/message/message'),
            meta: { title: '消息列表', keepAlive: true }
          },
          {
            path: '/message/messageRouteView/messageAdd',
            name: 'messageAdd',
            component: () => import('@/views/AllPages/message/messageAdd'),
            meta: { title: '添加消息', keepAlive: true }
          }
        ]
      },
      // 个人中心
      {
        path: '/account',
        component: RouteView,
        redirect: '/account/center',
        name: 'account',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: ['user'] },
        children: [
          {
            path: '/account/center',
            name: 'center',
            component: () => import('@/views/account/center'),
            meta: { title: '个人中心', keepAlive: true, permission: ['user'] }
          },
          {
            path: '/account/settings',
            name: 'settings',
            component: () => import('@/views/account/settings/Index'),
            meta: { title: '个人设置', hideHeader: true, permission: ['user'] },
            redirect: '/account/settings/basic',
            hideChildrenInMenu: true,
            children: [
              {
                path: '/account/settings/BasicSettings',
                name: 'BasicSettings',
                component: () => import('@/views/account/settings/BasicSetting'),
                meta: { title: '基本设置', hidden: true, permission: ['user'] }
              },
              {
                path: '/account/settings/SecuritySettings',
                name: 'SecuritySettings',
                component: () => import('@/views/account/settings/Security'),
                meta: {
                  title: '安全设置',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              },
              {
                path: '/account/settings/CustomSettings',
                name: 'CustomSettings',
                component: () => import('@/views/account/settings/Custom'),
                meta: { title: '个性化', hidden: true, keepAlive: true, permission: ['user'] }
              },
              {
                path: '/account/settings/BindingSettings',
                name: 'BindingSettings',
                component: () => import('@/views/account/settings/Binding'),
                meta: {
                  title: '账号绑定',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              },
              {
                path: '/account/settings/NotificationSettings',
                name: 'NotificationSettings',
                component: () => import('@/views/account/settings/Notification'),
                meta: {
                  title: '新消息通知',
                  hidden: true,
                  keepAlive: true,
                  permission: ['user']
                }
              }
            ]
          }
        ]
      },
      // 相关demo
      // dashboard
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/workplace',
        component: RouteView,
        meta: { title: 'menu.dashboard', keepAlive: true, icon: bxAnaalyse, permission: ['dashboard'] },
        children: [
          {
            path: '/dashboard/analysis/:pageNo([1-9]\\d*)?',
            name: 'Analysis',
            component: () => import('@/views/dashboard/Analysis'),
            meta: { title: 'menu.dashboard.analysis', keepAlive: false, permission: ['dashboard'] }
          },
          // 外部链接
          // {
          //   path: 'https://www.baidu.com/',
          //   name: 'Monitor',
          //   meta: { title: 'menu.dashboard.monitor', target: '_blank' }
          // },
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: 'menu.dashboard.workplace', keepAlive: true, permission: ['dashboard'] }
          }
        ]
      },
      // forms
      {
        path: '/form',
        redirect: '/form/base-form',
        component: RouteView,
        meta: { title: 'menu.form', icon: 'form', permission: ['form'] },
        children: [
          {
            path: '/form/base-form',
            name: 'BaseForm',
            component: () => import('@/views/form/basicForm'),
            meta: { title: 'menu.form.basic-form', keepAlive: true, permission: ['form'] }
          },
          {
            path: '/form/step-form',
            name: 'StepForm',
            component: () => import('@/views/form/stepForm/StepForm'),
            meta: { title: 'menu.form.step-form', keepAlive: true, permission: ['form'] }
          },
          {
            path: '/form/advanced-form',
            name: 'AdvanceForm',
            component: () => import('@/views/form/advancedForm/AdvancedForm'),
            meta: { title: 'menu.form.advanced-form', keepAlive: true, permission: ['form'] }
          }
        ]
      },
      // list
      {
        path: '/list',
        name: 'list',
        component: RouteView,
        redirect: '/list/table-list',
        meta: { title: 'menu.list', icon: 'table', permission: ['table'] },
        children: [
          {
            path: '/list/table-list/:pageNo([1-9]\\d*)?',
            name: 'TableListWrapper',
            hideChildrenInMenu: true, // 强制显示 MenuItem 而不是 SubMenu
            component: () => import('@/views/list/TableList'),
            meta: { title: 'menu.list.table-list', keepAlive: true, permission: ['table'] }
          },
          {
            path: '/list/basic-list',
            name: 'BasicList',
            component: () => import('@/views/list/BasicList'),
            meta: { title: 'menu.list.basic-list', keepAlive: true, permission: ['table'] }
          },
          {
            path: '/list/card',
            name: 'CardList',
            component: () => import('@/views/list/CardList'),
            meta: { title: 'menu.list.card-list', keepAlive: true, permission: ['table'] }
          },
          {
            path: '/list/search',
            name: 'SearchList',
            component: () => import('@/views/list/search/SearchLayout'),
            redirect: '/list/search/article',
            meta: { title: 'menu.list.search-list', keepAlive: true, permission: ['table'] },
            children: [
              {
                path: '/list/search/article',
                name: 'SearchArticles',
                component: () => import('../views/list/search/Article'),
                meta: { title: 'menu.list.search-list.articles', permission: ['table'] }
              },
              {
                path: '/list/search/project',
                name: 'SearchProjects',
                component: () => import('../views/list/search/Projects'),
                meta: { title: 'menu.list.search-list.projects', permission: ['table'] }
              },
              {
                path: '/list/search/application',
                name: 'SearchApplications',
                component: () => import('../views/list/search/Applications'),
                meta: { title: 'menu.list.search-list.applications', permission: ['table'] }
              }
            ]
          }
        ]
      },

      // profile
      {
        path: '/profile',
        name: 'profile',
        component: RouteView,
        redirect: '/profile/basic',
        meta: { title: 'menu.profile', icon: 'profile', permission: ['profile'] },
        children: [
          {
            path: '/profile/basic',
            name: 'ProfileBasic',
            component: () => import('@/views/profile/basic'),
            meta: { title: 'menu.profile.basic', permission: ['profile'] }
          },
          {
            path: '/profile/advanced',
            name: 'ProfileAdvanced',
            component: () => import('@/views/profile/advanced/Advanced'),
            meta: { title: 'menu.profile.advanced', permission: ['profile'] }
          }
        ]
      },

      // result
      {
        path: '/result',
        name: 'result',
        component: RouteView,
        redirect: '/result/success',
        meta: { title: 'menu.result', icon: 'check-circle-o', permission: ['result'] },
        children: [
          {
            path: '/result/success',
            name: 'ResultSuccess',
            component: () => import(/* webpackChunkName: "result" */ '@/views/result/Success'),
            meta: { title: 'menu.result.success', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
          },
          {
            path: '/result/fail',
            name: 'ResultFail',
            component: () => import(/* webpackChunkName: "result" */ '@/views/result/Error'),
            meta: { title: 'menu.result.fail', keepAlive: false, hiddenHeaderContent: true, permission: ['result'] }
          }
        ]
      },

      // Exception
      {
        path: '/exception',
        name: 'exception',
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: 'menu.exception', icon: 'warning', permission: ['exception'] },
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/403'),
            meta: { title: 'menu.exception.not-permission', permission: ['exception'] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404'),
            meta: { title: 'menu.exception.not-find', permission: ['exception'] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/500'),
            meta: { title: 'menu.exception.server-error', permission: ['exception'] }
          }
        ]
      },

      // other
      {
        path: '/other',
        name: 'otherPage',
        component: PageView,
        meta: { title: '其他组件', icon: 'slack', permission: ['dashboard'] },
        redirect: '/other/icon-selector',
        children: [
          {
            path: '/other/icon-selector',
            name: 'TestIconSelect',
            component: () => import('@/views/other/IconSelectorView'),
            meta: { title: 'IconSelector', icon: 'tool', keepAlive: true, permission: ['dashboard'] }
          },
          {
            path: '/other/list',
            component: RouteView,
            meta: { title: '业务布局', icon: 'layout', permission: ['support'] },
            redirect: '/other/list/tree-list',
            children: [
              {
                path: '/other/list/tree-list',
                name: 'TreeList',
                component: () => import('@/views/other/TreeList'),
                meta: { title: '树目录表格', keepAlive: true }
              },
              {
                path: '/other/list/edit-table',
                name: 'EditList',
                component: () => import('@/views/other/TableInnerEditList'),
                meta: { title: '内联编辑表格', keepAlive: true }
              },
              {
                path: '/other/list/user-list',
                name: 'UserList',
                component: () => import('@/views/other/UserList'),
                meta: { title: '用户列表', keepAlive: true }
              },
              {
                path: '/other/list/role-list',
                name: 'RoleList',
                component: () => import('@/views/other/RoleList'),
                meta: { title: '角色列表', keepAlive: true }
              },
              {
                path: '/other/list/permission-list',
                name: 'PermissionList',
                component: () => import('@/views/other/PermissionList'),
                meta: { title: '权限列表', keepAlive: true }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Recover')
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]
