import router from '@/router'
import store from '@/store'

// 建立sse链接
export const initSse = (userId) => {
  if (window.EventSource) {
    // 建立连接
    // console.log('process.env :>> ', process.env)
    // const source = new EventSource(process.env.VUE_APP_SSE_URL + '/api/sse/subscribe/' + userId)
    const source = new EventSource('/api/sse/subscribe/' + userId)

    source.addEventListener(
      'open',
      (e) => {
        console.log('建立链接success :>> ', e)
      },
      false
    )

    source.addEventListener('message', (e) => {
      if (e.data) {
        const data = JSON.parse(e.data)
        data.data = data.data ? JSON.parse(data.data) : data.data
        if (data.type === 'message') {
          console.log('接收到消息 :>> ', data)
          setTimeout(() => {
            store.commit('SET_RELOAD', store.state.user.vuex_reload)
            store.dispatch('getNum')
          }, 1000)
          sendNotification(data)
        } else {
          console.log('初始化建立链接 :>> ', data)
          setTimeout(() => {
            store.dispatch('getNum')
          }, 1000)
          sendNotification(data)
        }
      }
    })

    source.addEventListener(
      'error',
      (e) => {
        if (e.readyState === EventSource.CLOSED) {
          console.log('连接关闭')
        } else if (e.target.readyState === EventSource.CONNECTING) {
          console.log('Connecting...')
        } else {
          console.log('建立链接error :>>', e)
        }
      },
      false
    )
  } else {
    console.log('你的浏览器不支持SSE')
  }
}
function sendNotification (e) {
  // 请求显示通知的权限
  console.log('消息内容 :>> ', e)
  console.log('Notification状态 :>> ', Notification.permission)
  Notification.requestPermission().then(function (permission) {
    console.log('permission :>> ', permission)
    if (permission === 'granted') {
      // 创建通知对象
      var notification = null
      if (e.type === 'init') {
        // notification = new Notification('成功', {
        //   body: '推送通道建立成功',
        //   icon: '/logo3.png'
        // })
      } else {
        notification = new Notification(e.data.title, {
          body: e.data.content,
          icon: '/logo3.png'
        })
        // // 处理通知点击事件
        notification.onclick = (e) => {
          console.log('点击通知', e)
          router.push('/account/center?tab=message')
        }
      }
    }
  })
}
