// import request from '@/utils/request'

// eslint-disable-next-line no-unused-vars
import { axios as request, post, get } from '@/utils/request'

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc: 总平台登录
 */
export const login = (params, config = {}) => post('/api/login/passwordLogin', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc:  获取用户信息
 */
export const getInfo = (params, config = {}) => get('/api/login/getPhoneByToken', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc:获取当前用户菜单
 */
export const getCurrentUserNav = (params, config = {}) => get('/api/menu/menuList', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc:退出登录
 */
export const logout = (params, config = {}) => get('/api/login/logout', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc: 获取验证码
 */
export const getSmsCaptcha = (params, config = {}) => post('/api/sms/sendSMS', params, config)
/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc: 修改密码
 */
export const editPassword = (params, config = {}) => post('/api/login/editPassword', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc: 忘记密码
 */
export const forgetPassword = (params, config = {}) => post('/api/login/forgetPassword', params, config)

/**
 * javascript comment
 * @Author: wxz
 * @Date: 2023-12-04 19:16:38
 * @Desc:获取当前用户菜单权限
 */
export const getRoleParamsList = (params, config = {}) => get('/api/login/getRoleParamsList', params, config)
