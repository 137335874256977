<template>
  <div style="flex-wrap: nowrap; display: flex; align-items: center; justify-content: center">
    <a-badge
      :count="msgNum"
      style="margin-right: 32px"
      @click="goMsg">
      <div
        class="flex_AI_c flex_JC_c"
        style="background-color: #d6dde4; border-radius: 50%; width: 36px; height: 36px">
        <a-icon
          type="bell"
          style="font-size: 22px" />
      </div>
    </a-badge>
    <a-dropdown
      v-if="currentUser && currentUser.userName"
      placement="bottomRight">
      <div
        class="ant-pro-account-avatar"
        style="display: flex; align-items: center">
        <a-avatar
          size="large"
          :src="currentUser.avatar || defaultAvatar"
          class="antd-pro-global-header-index-avatar" />
        <div
          class="flex_col_JC_s flex_AI_c"
          style="margin-left: 10px; margin-right: 50px; color: #fff; font-size: 16px"
        >
          <span>{{ currentUser.userName }}</span>
          <span style="font-size: 12px; color: #bebebe">{{ currentUser.dept.deptName }}</span>
        </div>
      </div>
      <template #overlay>
        <a-menu
          class="ant-pro-drop-down menu"
          :selected-keys="[]">
          <a-menu-item
            v-if="menu"
            key="center"
            @click="handleToCenter">
            <a-icon type="user" />
            个人中心
          </a-menu-item>
          <a-menu-item
            v-if="menu"
            key="settings"
            @click="handleToSettings">
            <a-icon type="setting" />
            个人设置
          </a-menu-item>
          <a-menu-divider v-if="menu" />
          <a-menu-item
            key="logout"
            @click="handleLogout">
            <a-icon type="logout" />
            退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin
        size="small"
        :style="{ marginLeft: 8, marginRight: 8 }" />
    </span>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'

export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      defaultAvatar: require('@/assets/logo5.png')
    }
  },
  methods: {
    goMsg () {
      this.$router.push({ path: '/account/center', query: { tab: 'message' } })
    },
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout (e) {
      Modal.confirm({
        title: '退出登录',
        content: '您确定要退出登录吗？',
        onOk: () => {
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  :deep(.action) {
    margin-right: 8px;
  }
  :deep(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}
</style>
